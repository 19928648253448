import http from "@/http-common"

class TranslationDataService {
  fetchCategories(): Promise<any> {
    return http.get(import.meta.env.VITE_CATEGORIES_URL)
  }

  fetchAttributes(): Promise<any> {
    return http.get(import.meta.env.VITE_ATTRIBUTES_URL)
  }

  fetchLanguages(): Promise<any> {
    return http.get(import.meta.env.VITE_LANGUAGES_URL)
  }

  fetchLodgingLanguages() {
    return http.get(import.meta.env.VITE_LODGING_LANGUAGES_URL)
  }

  fetchPaymentOptions(): Promise<any> {
    return http.get(import.meta.env.VITE_PAYMENT_OPTIONS_URL)
  }
}

export default new TranslationDataService()
